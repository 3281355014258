.page-wrapper {
    width: 100%;
    height: max(calc(100vh - 3.5rem), 33rem);
    display: flex;
}

.form-wrap {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
}

.first-panel-wrap,
.second-panel-wrap {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.not-first-panel {
    transform: translateX(-100%);
}

.not-second-panel {
    transform: translateX(100%);
}

.first-panel {
    /* height: 30rem; */
    width: min(90%, 26rem);
    border: 2px solid lightgray;
    border-radius: 10px;
    margin: auto;
}

.big-text-wrap {
    display: flex;
    justify-content: space-between;
    padding: 15% 15% 10% 15%;
    
}

.big-text {
    font-size: 1.75rem;
    font-weight: 500;
}

.close-icon {
    font-size: 1.75rem;
}

.input-wrap {
    width: 100%;
    padding: 0 15% 5% 15%;
}

.info-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}

.info-text {
    text-transform: uppercase;
    font-weight: 500;
}

.input {
    width: 100%;
    height: 2.5rem;
    padding: 2% 5%;
    border-radius: 10px;
    border: 1px solid black;
}
.wrong-info {
    border: 1px solid red;
}

.not-cool {
    border: 2px solid red;
}

.clickable-text-small {
    font-size: 0.85rem;
    color: var(--primary);
    text-decoration: underline;
}

.clickable-text-small:hover {
    color: black;
}

.clickable-text-small:active {
    color: var(--special);
}

.show-button {
    user-select: none;
    vertical-align: middle;
    line-height: 1.5rem;
}

.continue-wrap {
    width: 100%;
    display: flex;
}

.continue-button {
    cursor: pointer;
    user-select: none;
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid var(--primary);
    color: var(--primary);
    padding: 5px 20px;
    font-weight: 600;
    margin-top: 12px;
    text-transform: uppercase;
    margin: auto;
}

.continue-button:active {
    color: white;
    background-color: var(--primary);
}

.submit-button {
    cursor: pointer;
    user-select: none;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
    color: black;
    padding: 5px 20px;
    font-weight: 600;
    margin-top: 12px;
    text-transform: uppercase;
}

.sign-in-wrap {
    margin: 5% 15% 15% 15%;
    display: flex;
    justify-content: space-evenly;
    font-size: 0.85rem;
}

.sign-in-text {
    font-weight: 500;
}

.second-panel {
    /* height: 30rem; */
    width: min(90%, 26rem);
    border: 2px solid lightgray;
    border-radius: 10px;
    margin: auto;
}

.complete-profile-wrap {
    justify-content: unset;
}

.complete-profile-wrap .big-text {
    margin: auto;
}

.gender-wrap {
    padding-bottom: 2%;
}

.gender-input-wrap {
    display: flex;
    width: 100%;
}

.gender-input-container {
    display: flex;
    width: 50%;
}

.gender-input {
    display: none;
    
}

.gender-checkbox {
    width: 1.75rem;
    height: 1.75rem;
    line-height: 1.75rem;
    text-align: center;
    font-size: 1.25rem;
    border-radius: 10px;
    transition: background-color 0.2s linear;
    margin-right: 1rem;
}

.gender-checkbox.checked {
    background-color: var(--primary);
}

.gender-checkbox.checked span {
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gender-checkbox.unchecked {
    border: 1px solid black;
    box-sizing: border-box;
}

.gender-checkbox.unchecked span {
    visibility: hidden;
}

.second-panel-buttons-wrap {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.continue-wrap .continue-button {
    margin: 12px auto auto auto;
}

.second-panel-buttons-wrap .continue-button {
    margin: unset;
    margin-top: 12px;
}

@media (max-width: 450px) {
    .big-text-wrap {
        padding: 10% 10% 10% 10%;
    }

    .input-wrap {
        padding: 0 10% 5% 10%;
    }

    .sign-in-wrap {
        margin: 5% 10% 10% 10%;
    }

    /* .gender-wrap {
        padding: 0 10%;
    } */
}

.duplicate-message {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F4284C;
    font-weight: 800;
    margin-top: 5%;
}

.remember-me-wrap {
    display: flex;
    flex-direction: row;
    width: auto;
    padding: 0 15% 0 15%;
}

.remember-me-checkbox {
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 10px;
    font-size: 1.25rem;
    text-align: center;
    transition: background-color 0.2s linear;

}


.remember-me-checkbox.checked {
    background-color: var(--primary);
}

.remember-me-checkbox.checked span {
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.remember-me-checkbox.unchecked {
    border: 1px solid black;
    box-sizing: border-box;
}

.remember-me-checkbox.unchecked span {
    visibility: hidden;
}

.remember-me-text {
    user-select: none;
    height: 100%;
    line-height: 1.75rem;
    margin-left: 5px;
}

@media (max-width: 320px) {
    .big-text-wrap {
        padding: 5% 5% 10% 5%;
    }

    .input-wrap {
        padding: 0 5% 5% 5%;
    }

    .sign-in-wrap {
        margin: 5% 5% 5% 5%;
    }

    /* .gender-wrap {
        padding: 0 5%;
    } */
}