.sign-in-wrapper {
    width: 100%;
    height: max(calc(100vh - 3.5rem), 33rem);
    display: flex;
}

.sign-in-panel {
    border: 2px solid lightgray;
    border-radius: 10px;
    margin: auto;
    width: min(95%, 26rem)
}

.big-text-wrap {
    display: flex;
    justify-content: space-between;
    padding: 15% 15% 10% 15%;
}

.big-text {
    font-size: 1.75rem;
    font-weight: 500;
    
}

.close-icon {
    font-size: 1.75rem;
}

.password-wrap, .email-wrap {
    width: 100%;
    padding: 0 15% 5% 15%;
}

.info-text-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}

.info-text {
    text-transform: uppercase;
    font-weight: 500;
}

.info-box {
    width: 100%;
    height: 2.5rem;
    padding: 2% 5%;
    border-radius: 10px;
    border: 1px solid black;
}

.wrong-info {
    border: 1px solid red;
}

.wrong-message {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F4284C;
    font-weight: 800;
    margin-top: 5%;
}
.show-button {
    line-height: 1.5rem;
    vertical-align: middle;
    /* margin-bottom: 4px; */
    font-size: 0.85rem;
    user-select: none;
    text-decoration: underline;
    color: var(--primary);
}

.show-button:hover {
    color: black;
}

.show-button:active {
    color: var(--special);
}

.memory-foam {
    width: 100%;
    padding: 0 15%;
    display: flex;
    justify-content: space-between;
}

.remember-me-wrap {
    display: flex;
    flex-direction: row;
    width: auto;
}

.remember-me-checkbox {
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 10px;
    font-size: 1.25rem;
    text-align: center;
    transition: background-color 0.2s linear;

}


.remember-me-checkbox.checked {
    background-color: var(--primary);
}

.remember-me-checkbox.checked span {
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.remember-me-checkbox.unchecked {
    border: 1px solid black;
    box-sizing: border-box;
}

.remember-me-checkbox.unchecked span {
    visibility: hidden;
}

.remember-me-text {
    user-select: none;
    height: 100%;
    line-height: 1.75rem;
    margin-left: 5px;
}

.submit-wrap {
    width: 100%;
    display: flex;
}

.submit-button {
    margin: auto;
    padding: 5px 20px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 10px;
    background-color: var(--secondary);
    border: none;
    margin-top: 12px;
}

.register-wrap {
    margin: 5% 15% 15% 15%;
    display: flex;
    justify-content: space-evenly;
    font-size: 0.85rem;
}

.reg-text {
    font-weight: 500;
}

.reg-link a {
    text-decoration: underline;
    color: var(--primary);
}

.reg-link a:hover {
    color: black;
}

.reg-link a:active {
    color: var(--special);
}

.clickable-text-small {
    font-size: 0.85rem;
    color: var(--primary);
    text-decoration: underline;
}

.clickable-text-small:hover {
    color: black;
}

.clickable-text-small:active {
    color: var(--special);
}


@media (max-width: 450px) {
    .big-text-wrap {
        padding: 10% 10% 10% 10%;
    }

    .password-wrap, .email-wrap {
        padding: 0 10% 5% 10%;
    }

    .memory-foam {
        padding: 0 10%;
    }

    .register-wrap {
        margin: 5% 10% 10% 10%;
    }
}

@media (max-width: 320px) {
    .big-text-wrap {
        padding: 5% 5% 10% 5%;
    }

    .password-wrap, .email-wrap {
        padding: 0 5% 5% 5%;
    }

    .memory-foam {
        padding: 0 5%;
    }

    .register-wrap {
        margin: 5% 5% 5% 5%;
    }
}

