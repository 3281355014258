.panel {
  width: 90%;
  /* height: 250%; */
  display: flex;
  align-items: center;
  padding: 3%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3%;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 4px 20px 0px rgba(43, 82, 183, 0.1);
}
.panel p {
  font-weight: 800;
}

.tickboxes {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.1em;
  
}

.option {
  display: flex;
  border-radius: 10px;
  border: 2px solid var(--primary-variant, #eaeffa);
  padding: 1%;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  user-select: none;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
}

.before-primary-label {
  height: 20px;
  width: 20px;
  border: 2px solid var(--primary);
  border-radius: 5px;
  margin-right: 15px;
  color: white;
  text-align: center;
}
.content {
  width: 100%
}

.label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.user-agreement {
  margin-top: 1.2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5%;
  width: 65%;
  user-select: none;
  font-size: 0.9em;
}

.user-agreement label {
  width:90%;
}
.agree-checkbox {
  width: 15px;
  height: 15px;
}


@media (max-width: 1100px) {
  .tickboxes {
    font-size: 0.65em
  }
}

@media (max-width: 860px) {
  .tickboxes {
    grid-template-columns: 1fr;
  } 
  .user-agreement{
    font-size: 0.9em;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .option {
    font-size: 1em;
  }
}