@font-face {
  font-family: 'Geotica Four Open';
  src: local('Geotica Four Open'), url('../../Fonts/GeoticaFour-Open.ttf') format('truetype');
}

.home-header {
  width: 100%;
  height: 250vh;
  position: relative;
  --clock-and-logo-offset: -1.2rem;
  --clock-width-height: 90vh;
}

.header-video {
  width: 100%;
  height: 250vh;
  position: absolute;
  object-fit: cover;
  z-index: 1;
}

.beforemain-wrap, .aftermain-wrap {
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.beforemain {  
  top: -6rem;
}

.aftermain {
  top: 2.5rem;
}

.beforemain, .aftermain {
  color: white;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: lighter;
  font-size: 1.5rem; 
  position: relative;
}

.main-text-wrap {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 5;
}

.main-text {
  /* position: absolute;
  top: calc(50vh - 5vh); */
  position: relative;
  top: var(--clock-and-logo-offset);
  font-family: 'Geotica Four Open';
  text-transform: uppercase;
  color: #B5AC7F;
  text-align: center;
  font-size: 5rem;
  font-weight: 600;
  height: 10vh;
  line-height: 10vh;
  width: 100%;
}

.clock-wrap-wrap {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  z-index: 4;
}

.clock-wrap {
  position: relative;
  top: var(--clock-and-logo-offset);
  width: var(--clock-width-height);
  height: var(--clock-width-height);
  z-index: 4;
}

.hour-mark {
  --hm-color: whitesmoke;
  position: absolute;
  width: 100%;
  height: 2px;
  box-sizing: border-box;
  background-color: transparent;
  border-right: 70px solid var(--hm-color);
  border-left: 70px solid var(--hm-color);
  top: calc(50% - 1px);
  z-index: 4;
  /* border-left and border-right 
  values are controlled in media 
  queries, these are just for backup*/
}

.hour-mark:nth-of-type(2) {
  transform: rotate(30deg);
}

.hour-mark:nth-of-type(3) {
  transform: rotate(60deg);
}

.hour-mark:nth-of-type(4) {
  transform: rotate(90deg);
}

.hour-mark:nth-of-type(5) {
  transform: rotate(120deg);
}

.hour-mark:nth-of-type(6) {
  transform: rotate(150deg);
}

.long-hand {
  --lh-color: whitesmoke;
  --long-hand-length: calc(50% - 10vh);
  --long-hand-offset: 0.1;
  position: absolute;
  width: var(--long-hand-length);
  height: 2px;
  background-color: var(--lh-color);
  top: calc(50% - 1px);
  right: calc(50% - (var(--long-hand-length) * var(--long-hand-offset)));
  transform-origin: calc(100% - (100% * var(--long-hand-offset)));
  animation: 12s steps(12) 0s infinite normal both running long-hand-animation;
  z-index: 4;
}

.short-hand {
  --sh-color: whitesmoke;
  --short-hand-length: calc((50% - 10vh) * 0.7);
  --short-hand-offset: 0.1;
  position: absolute;
  width: var(--short-hand-length);
  height: 2px;
  background-color: var(--sh-color);
  top: calc(50% - 1px);
  right: calc(50% - (var(--short-hand-length) * ( var(--short-hand-offset))));
  transform-origin: calc(100% - (100% * var(--short-hand-offset)));
  transform: rotate(-32deg);
}

@keyframes long-hand-animation {
  from {
      transform: rotate(90deg);
  }
  to {
      transform: rotate(450deg);
  }
}

.afterclock-wrap {
  position: absolute;
  top: 100vh;
  width: 100%;
  z-index: 6;
}

.afterclock {
  width: 100%;
}

.learn-more-wrap, .down-arrow-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.learn-more-wrap {
  top: 1rem;
  color: white;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: lighter;
  font-size: 1.3rem;
}

.learn-more {}

.down-arrow-wrap {
  top: 2rem;
}

.down-arrow {
  color: var(--secondary);
  font-size: 1.3rem;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  /* line-height must have the same value
  as height to center text vertically*/
  text-align: center;
}

.down-arrow:hover {
  cursor: pointer;
}

.goals-wrap {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 150vh;
  z-index: 6;
}

.upper-goal {
  font-size: 3rem;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;
  text-align: center;
  position: relative;
  top: 20vh;
}

.goal-line {
  height: 3px;
  width: 200px;
  position: relative;
  top: 20vh;
  background-color: var(--secondary);
  left: calc(50% - 200px / 2);
}

.lower-goal-text {
  position: relative;
  top: 25vh;
  color: white;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  width: 50%;
  left: calc(50% - 50% / 2);
  text-align: justify;
}

.join-us {
  background: url('./../assets/StockSnap_SXKQKPQXWE.jpg');
  height: 325px;
  background-size: 100% auto;
  background-position: top;
  
}

.join-us-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.join-us-main-text {
  font-size: 1.5rem;
  color: white;
  font-weight: 600;
  text-align: center;
  
}

.join-us-button {
  font-size: 1rem;
  color: #000;
  background-color: var(--secondary);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px 20px;
  font-weight: 600;
  user-select: none;
  cursor: pointer;
}

@media (max-width: 1150px) {
  .lower-goal-text {
    width: 70%;
    left: calc(50% - 70% / 2);
  }
}

@media (max-width: 600px) {
  .lower-goal-text {
    width: 90%;
    left: calc(50% - 90% / 2);
  }
}

@media (min-aspect-ratio: 1/1) {
  .home-header {
    --clock-width-height: 90vh;
  }
  .hour-mark {
    border-right: 10vh solid var(--hm-color);
    border-left: 10vh solid var(--hm-color);
  }
}

@media (max-aspect-ratio: 1/1) {
  .home-header {
    --clock-width-height: max(90%, 500px);
  }
  .clock-wrap {
    min-width: 400px;
    min-height: 400px;
  }
  .hour-mark {
    border-right: max(10vw, 50px) solid var(--hm-color);
    border-left: max(10vw, 50px) solid var(--hm-color);
  }
}

@media (max-width: 470px) {
  .home-header {
    overflow: hidden;
  }
  .main-text {
    font-size: min(4rem, calc(100vw / 7));
  }
}

@media (max-width: 1400px) {
  .join-us {
    background-size: auto 100%;
  }
}

@media (max-width: 300px) {
  .home-header, .header-video {
    height: 300vh;
  }

  .beforemain, .aftermain {
    font-size: 1.4rem;
  }
}