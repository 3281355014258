.page-wrapper {
  height: 100%;
  width: 100%;
  padding: 5% 15%;
}

.article-title {
  text-align: center;
  color: var(--secondary);
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.article-category {
  text-align: center;
  color: var(--primary);
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.article-authors {
  text-align: center;
  font-size: 1.25rem;
  font-style: italic;
  margin-bottom: 30px;
}

.click-text {
  text-align: center;
}

.click-text a {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
}

.article-abstract-wrap {
  margin-top: 30px;
}

.article-abstract-title {
  font-size: 3rem;
  font-weight: 600;
  color: var(--primary);
  margin-bottom: 10px;
}

.article-abstract {
  font-size: 1.125rem;
  line-height: 2;
}

@media (max-width: 850px) {
  .page-wrapper {
    padding: 5%;
  }
}

@media (max-width: 700px) {
  .article-title {
    font-size: 2rem;
  }

  .article-category, .article-authors {
    font-size: 1rem;
  }

  .article-abstract-title {
    font-size: 2rem;
  }

  .article-abstract {
    font-size: 1rem;
    text-align: justify;
  }
}

@media (max-width: 500px) {
  .page-wrapper {
    padding: 5% 2.5%; 
  }
}