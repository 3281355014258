.upload-page {
    width: 100%;
    user-select: none;
}

.upload-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 3% 17% 5% 17%;
    justify-content: center;
    align-items: center;
    gap: 5%; 
}

.title-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: solid 4px #FFBC00;
}

.big-title {
    color: #2B52B7;
    font-size: 3em;
    font-weight: 700;
    margin: 0;
}

.small-title {
    color: black;
    font-size: 2.5em;
    font-weight: 700;
    margin: 0;
}
.step {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.step-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 3%;
    margin-bottom: 3%;
}
.step-title span {
    font-size: 2.6em;
    font-weight: 900;
    color: #1A1A1A;  
    display: flex;
    justify-content: center;
    align-items: center;
}
.title-icon {
    font-size: 2.6em;
    font-weight: 900;
    color: #1A1A1A; 
    width: 6.5%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: #FFBC00;
    display: flex;
    align-items: center;
    justify-content: center;
}

.first-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 2%
}

.first-content-wrapper {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 3%;
    margin-left: 1%;
}

.first-content-wrapper span {
    width: 90%;
    display: flex;
    align-items: center;
}

.first-content-icon {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #EAEFFA;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2B52B7;
    font-weight: 900;
}

.second-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 2%
}

.second-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 3%;
}

.phase-title-wrapper {
    font-size: 1.2em;
    color: #2B52B7;
    font-weight: 900;
}

.phase-number {
    padding: 0.5% 1%;
    background-color: #EAEFFA;
    border-radius: 10px;
    margin-right: 2%
}

.phase-content {
    margin-left: 2%;
    padding-left: 1%;
    border-left: 3px solid #EAEFFA;
    margin-top: 1%;
    color: #1A1A1A;
}

.status-preview {
    display: flex;
    flex-direction: row;
    gap: 6%;
    margin-top: 3%
}

.status-bar {
    padding: 0.5% 1%;
    background-color: #EAEFFA;
    border-radius: 10px;
    font-size: 1.2em;
    font-weight: 900;
}

.accepted {
    color: #20C198;
    background: rgba(32, 193, 152, 0.20);
}

.rejected {
    color: #F4284C;
    background: rgba(244, 40, 76, 0.20);;
}

.on-waitlist {
    color: #9FA2AB;
    background: rgba(159, 162, 171, 0.20);
}


.pop-up-wrapper {
    display: flex;
    position: sticky;
    bottom: 20px;
    margin-bottom: 35px;
}

.pop-up {
    background: white;
    margin: auto;
    display: flex;
    flex-direction: row;
    width: min(85%, 400px);
    justify-content: space-between;
    align-items: center;
    padding: 5px 5px 5px 20px;
    border-radius: 10px;
    box-shadow: 0 0 6px 0 lightgrey;
}

.pop-up-text {
    color: var(--primary);
    font-weight: 700;
    font-size: 1.1rem;
}

.pop-up-button {
    user-select: none;
    color: var(--primary);
    font-size: 1.1rem;
    font-weight: 700;
    text-transform: uppercase;
    padding: 5px 15px;
    border-radius: 10px;
    background: linear-gradient(to right, #2B52B751, #FFBC0051);
}

.pop-up-button:active {
    background: linear-gradient(to right, #2B52B777, #FFBC0077)
}

@media (max-width: 1295px) {
    .title-icon {
        width: 10%
    }
}
@media (max-width: 900px) {
    .title-icon {
        width: 15%
    }
}
@media (max-width: 700px) {
    .big-title {
        font-size: 2em
    }
    
    .small-title {
        font-size: 1.8em
    }
}

@media (max-width: 600px) {
    .title-icon {
        width: 15%;
        font-size: 1.6em
    }
    .step-title span {
        font-size: 1.7em;
    }
}

@media (max-width: 500px) {
    .upload-icon {
        display: none;
    }

    .pop-up-text, .pop-up-button {
        font-size: 0.9rem;
    }
}
@media (max-width: 450px) {
    .title-icon {
        width: 14%;
    }
    .step-title span {
        font-size: 1.4em
    }
    .big-title {
        font-size: 1.5em
    }
    
    .small-title {
        font-size: 1.4em
    }
    .upload-page {
        padding: 2% 10% 3% 10%
    }
    .status-bar {
        font-size: 0.8em
    }
}

@media (max-width: 330px) {
    .title-icon {
        width: 13%;
    }
    .step-title span {
        font-size: 1.2em
    }
    
}