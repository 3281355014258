:root{
    --primary: #2B52B7;
    --secondary: #FFBC00;
    --special: #FF0000; 
}


ul{
    list-style: none;
};

body{
    height: 100%;
    width: 100%;
}

.page-wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

.filter-caption{
    width: 100%;
    height: 10%;
    color: var(--primary);
    font-weight: 700;
    font-size: 1.5em;
}


.filter-box{
    height: 100%;
    width: 25vw;
    padding-left: 2%;
    padding-top: 2%;
    padding-bottom: 2%;
    display: inline-block;
    margin: 1%;
    border-radius: 5px;
    border: 1px aliceblue solid;
    
}



.no-list-style{
    list-style: none;
}

.sublist1{
    margin-left: 3%;
    border-left: 2px black solid;
    padding-left: 4%;
    font-weight: 600;
    
}
.sub-list-items{
    margin-top: 4%;
    font-size: 0.8em; 
    align-items: center;
    font-weight: lighter;
}

.filter-checkbox {
    appearance: none;
    -webkit-appearance: none;
    height: 1.2em;
    width: 1.2em;
    background-color: white;
    margin-right: 1%;
    border-radius: 4px;
    border: black 1px solid;
    cursor: pointer;
    
}
label{
    cursor: pointer;
    width: 100%;
}

/* input[type="checkbox"]::after{
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f00c";
    font-size: 1em;
    color: grey;
    display: none;
    outline: none;
} */

/* 
input[type="checkbox"]:checked::after{
    display: inline-block;
} */

input[type="checkbox"]:hover{
    background-color: gainsboro;
}

input[type="checkbox"]:checked{
    background-color: var(--primary);
}

.header{
    margin-top: 4%;
}
.sublist2{
    margin-top: 4%;
    font-weight: 600;
    padding-left: 0;
}

@media (max-width: 500px){
    .filter-caption{
        font-size: 1em;
    }
    .sublist1{
        border-left: 2px black solid;
        padding-left: 4%;
        
    }
    .sub-list-items{
        margin-top: 0%;
        font-size: 0.7em; 
    }
    .header{
        margin-top: 3%;
    }
    .sublist2{
        margin-top: 3%;
    }
    h2{
        font-size: 0.8em;
    }
    .filter-box{
        width: 90vw;
    }
    
}



/* this is where the filter box stops */
.search-box{
    margin: 1%;
    height: 100%;
    width: 70%;
    display: inline-block;
}

.search-bar{
    height: 100%;
    width: 100%;
    border: 2px solid black;
    border-radius: 5px;
    outline: none;
    font-size: 1em;
    display: flex;
}
.search-input{
    width: 95%;
    padding: 1%;
    display:inline-block;
    font-size: 1.1em
}
.search-button{
    width: clamp(5%,20px,10%);
    height: auto;
    
} 

.search-results{
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
} 