.page-wrapper {
  width: clamp(30%, 320px, 95%);
  height: clamp(70%, 450px, 90%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.panel {
  display: flex;
  width: 100%;
  background-color: brown;
  height: 60%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(43, 82, 183, 0.1);
  padding: 1%;
  user-select: none;
}
.icon {
  height: 40%;
  width: 40%;
  position: relative;
  top: 20%;
  filter: drop-shadow(0px 4px 20px rgba(43, 82, 183, 0.1));
}

.message {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.message p {
  width: 70%;
  text-align: center;
}

.return-button {
  display: flex;
  width: 50%;
  padding: 3%;
  border: 0;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--primary-main, #2b52b7);
  color: #fff;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.error-return-button {
  display: flex;
  width: 40%;
  padding: 2%;
  border: 0;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #F4284C;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.success-message {
  background: linear-gradient(180deg, rgba(234, 239, 250, 0.00) 0%, rgba(43, 82, 183, 0.40) 100%);
}
.error-message {
  background: linear-gradient(180deg, rgba(234, 239, 250, 0.00) 0%, #F6D6DB 100%);
}