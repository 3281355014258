.panel {
  width: clamp(70%, 800px, 90%);
  display: flex;
  align-items: center;
  padding: 5%;
  padding-bottom: 7%;
  flex-direction: column;
  align-items: flex-start;
  gap: 5%;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 4px 20px 0px rgba(43, 82, 183, 0.1);
}

.panel p {
  font-size: 1.2em;
  font-weight: 700;
}
.choices {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3%;
}

.radio-button {
  display: none;
}


.option {
  display: flex;
  border-radius: 10px;
  border: 1px solid var(--primary-variant, #eaeffa);
  padding: 2%;
  margin: 3px 0;
  user-select: none;
}

.chosen {
  border: 1px solid var(--primary-main, #2b52b7);
}
.chosen-label {
  background-color: #2b52b7;
}


.primary-label-5 {
  position: relative;
  display: flex;
  width: 150%;
  height: 150%;

}
.before-primary-label {
  height: 20px;
  width: 20px;
  border: 2px solid var(--primary);
  border-radius: 50%;
  margin-right: 15px
}