.panel {
  width: clamp(70%, 800px, 90%);
  display: block;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 2%;
  flex-direction: column;
  align-items: flex-start;
  gap: 5%;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 4px 20px 0px rgba(43, 82, 183, 0.1);
}

.primary-question {
  border-radius: 10px;
  width: 100%;
  background: var(--primary-variant, #eaeffa);
  display: flex;
  padding: 5%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10%;
  align-self: stretch;
  height: 15%;
  color: #000;
  font-family: Roboto;
  font-size: 1em;
  font-weight: 500;
}

.primary-question span {
  font-style: italic;
  font-weight: 800;
}

.choices {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2%
}

.option-wrapper {
  width: 50%;
}
.option-header {
  color: #000;
  font-family: 'Roboto';
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.option {
  padding: 1.5%;
  display: flex;
  border-radius: 10px;
  border: 1px solid var(--primary-variant, #eaeffa);
  padding: 2%;
  user-select: none;
}


.checkbox {
  display: none;
}

.chosen-label {
  background-color: #2b52b7;
}
.secondary-label {
  position: relative;
  display: flex;
}
.before-secondary-label {
  height: 20px;
  width: 20px;
  font-size: 15px;
  border: 2px solid var(--primary);
  border-radius: 5px;
  margin-right: 5px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.not-vacant {
  border-radius: 10px;
  border: 1px solid var(--primary-main, #2b52b7);
  color: grey;
}

.not-vacant-label {
  pointer-events: none;
}
.not-vacant-label .before-secondary-label {
  background-color: #2b52b7;
  opacity: 0.5;
}

.chosen {
  border: 1px solid var(--primary-main, #2b52b7);
}

@media (max-width: 450px) {
  .option-header {
    font-size: 0.7em;
  }
  label {
    font-size: 0.7em;
  }
  .primary-question {
    font-size: 0.7em;
  }
}

