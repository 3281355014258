.nav-wrap {
    position: fixed;
    top: 0;
    width: 100%;
    transition: top 0.15s linear 0.05s;
    z-index: 999;
}

.shown {
    top: 0;
}

.hidden {
    top: -3.5rem;
    /* has to be the value of nav-wrap's height but negative */
}

.navbar {
    height: 3.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    z-index: 998;
    background-color: black;
    width: 100%;
}

.left-container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    /* width: 50%; */
}

.logo-container {
    display: block;
    height: 50%;
    margin-bottom: 3%;
}

.logo {
    height: 100%;
    align-self: center;
    justify-self: center;
    display: inline-block;
}

.site-name-container {
    margin-left: 0.3rem;
}

.site-name {
    line-height: 3.5rem;
    font-size: 1.25rem;
    font-weight: bold;
    color: white;

}

.menu-container {
    font-size: 1.8rem;
    aspect-ratio: 1/1;
    height: 2rem;
    text-align: center;
    margin-right: 5%;
    margin-left: 15%;
}

.menu-icons {
    color: #fff;
    line-height: 1.5rem;
}
svg {
    height: 0.6em !important
}


.nav-menu.active {
    height: 100%;
    visibility: visible;
    position: fixed;
    width: 15%;
    top: 3.5rem;
    background-color: black;
    z-index: 999;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

.nav-menu.inactive {
    visibility: hidden;
    position: fixed;
}
.nav-directories {
    height: 30%;
    padding-left: 7%
}

.nav-pages {
    margin: 5%;
    margin-left: 2%;
}

.nav-links {
    text-decoration: none;
    color: white;
    font-size: 15px;
    font-weight: 500
}

.right-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
}

.register-button-wrap.show, .login-button-wrap.show {
    height: 60%;
    display: flex;
    box-sizing: border-box;
    border-radius: 10px;
    margin-right: 15px;
    padding: 0 15px 0 15px;
    transition: 0.15s linear;
}

.register-button-wrap.show {
    border: 2px solid var(--secondary);   
}

.register-button-wrap.show:hover {
    background-color: var(--secondary);
}

.register-button-wrap.show:hover .register-button-link {
    color: black;
}

.login-button-wrap.show:hover {
    background-color: black;
}

.login-button-wrap.show:hover .login-button-link {
    color: var(--secondary);
}

.login-button-wrap.show {
    background-color: var(--secondary);
    border: 2px solid var(--secondary);
}

.register-button-link {
    color: var(--secondary);
}

.login-button-link {
    color: black;
}

.register-button-link, .login-button-link {
    text-decoration: none;
    margin: auto;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: bold;
}

.register-button, .login-button {
    visibility: visible;
}

.hide {
    display: none;
}

.username-box-wrap.show {
    user-select: none;
    height: 50%;
    display: flex;
    box-sizing: border-box;
    color: black;
    background-color: var(--secondary);
    border-radius: 10px;
    border: 2px solid var(--secondary);
    padding: 0 15px 0 15px;
    font-size: 0.85rem;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-right: 15px;
    transition: 0.15s linear;
}

.username-box-wrap.show:hover {
    color: var(--secondary);
    background-color: black;
}

.username-box-wrap.show:active {
    color: var(--primary);
}

.username-box {
    margin: auto;
}

.username-box-wrap.hide {
    display: none;
}

.activeudda {
    position: fixed;
    top: 3rem;
    right: 15px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: white;
    padding: 4px 12px;
    cursor: pointer;
}

.uddi {
    user-select: none;
    font-size: 1rem;
    margin: 5px 0;
}

.uddi:active {
    color: var(--primary);
}

.uddic {
    margin-right: 10px;
}

.logout {
    color: var(--special);
}

.logout:active {
    color: black;
}

.inactiveudda {
    display: none;
}

.nav-gutter {
    width: 100%;
    height: 3.5rem;
    /* has to be the same value as height of nav-wrap*/
    background-color: black;
}

@media (max-width: 1000px) or (max-aspect-ratio: 1/1) {
    .nav-menu.active {
        width: 40%;
        top: 3rem;
    }
}

@media (max-width: 600px) {
    .nav-menu.active {
        width: 70%;
    }

    .site-name-container {
        display: none;
    }
}

@media (max-width: 320px) {
    .logo-container {
        display: none;
    }

    .hidden {
        top: -3rem;
    }

    .menu-container {
        height: 3rem;
    }

    .nav-menu.active {
        width: 70%;
    }

    .navbar {
        height: 3rem;
    }

    .nav-gutter {
        height: 3rem;
    }

    .menu-icons {
        line-height: 3rem;
    }

    .site-name {
        line-height: 3rem;
    }
}