:root {
  --primary: #2b52b7;
  --secondary: #ffbc00;
  --special: #ff0000;
}

ul {
  list-style: none;
}

body {
  height: 100%;
  width: 100%;
}

.page-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.filter-caption {
  color: var(--primary);
  font-weight: 700;
  font-size: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter-box {
  height: 100%;
  width: 250px;
  display: block;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 2px 40px -20px #c0c0c0;
  padding: 10px;
}

.filter-wrap {
  margin-left: 10px;
  box-sizing: border-box;
  position: sticky;
  top: calc(3.5rem + 10px);
}

.filter-by-text-2 {
  margin-left: 10px;
  text-transform: uppercase;

}

.no-list-style {
  list-style: none;
}

.sublist1 {
  margin-left: 3%;
  border-left: 2px black solid;
  padding-left: 4%;
  font-weight: 600;
}

.sub-list-items {
  margin-top: 4%;
  font-weight: lighter;
  font-size: 0.8rem;
}

.sub-list-items label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter-checkbox-text {
  margin-left: 5px;
}

.filter-checkbox {
  appearance: none;
  -webkit-appearance: none;
  height: 1.2rem;
  width: 1.2rem;
  background-color: white;
  border-radius: 4px;
  border: black 1px solid;
  cursor: pointer;
  overflow: hidden;
}

label {
  cursor: pointer;
  width: 100%;
}

/* input[type="checkbox"]::after{
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f00c";
    font-size: 1em;
    color: grey;
    display: none;
    outline: none;
} */

/* 
input[type="checkbox"]:checked::after{
    display: inline-block;
} */

input[type="checkbox"]:hover {
  background-color: gainsboro;
}

input[type="checkbox"]:checked {
  background-color: var(--primary);
}

.header {
  margin-top: 4%;
}

.sublist2 {
  margin-top: 4%;
  font-weight: 600;
  padding-left: 0;
}

.social-sciences > li, .natural-sciences > li {
  font-weight: 700;
  font-size: 1rem;
}

@media (max-width: 450px) {
  .sub-list-items {
    font-size: 1.2rem;
    line-height: 1.2rem;

  }

  .social-sciences > li, .natural-sciences > li {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
}

/* this is where the filter box stops */
.search-box {
  width: calc(100% - 280px);
  height: 100%;
  display: block;
  margin-left: 10px;
  margin-right: 10px;
}

.search-bar {
  box-sizing: border-box;
  height: 3rem;
  width: 100%;
  font-size: 1rem;
  display: flex;
  overflow: hidden;
}

.search-input {
  flex-grow: 1;
  border: 1px solid lightgray;
  border-radius: 10px 0 0 10px;
  display: block;
  padding-left: 10px;
  font-size: 1rem;
  z-index: 1;
}

.search-button {
  height: 100%;
  aspect-ratio: 1/1;
  opacity: 10%;
  pointer-events: none;
  background-color: white;
  border: 1px solid lightgray;
  margin-left: -1px;
  border-radius: 0 10px 10px 0;
  z-index: 1;
}

.search-button:focus,
.search-input:focus {
  outline: none;
  border: 2px solid var(--primary);
  z-index: 2;
}

.search-button:hover {
  background-color: #c89300
}

.search-button:active {
  background-color: lightgray;
}

.pagination {
  margin-top: 15px;
}

.selectable-search-button {
  opacity: 100%;
  pointer-events: auto;
  background-color: var(--secondary)
}

/* @media (max-width: 700px){
  .search-icon {
    display: none;
  }
} */

.search-results {
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
}

.card-row {
  width: 100%;
  height: 100%;
  margin: 0;
}

.individual-card {
  width: calc(50% - 7.5px);
}

.pdf-wrap {
  object-fit: contain;
  height: 100%;
  width: 100%;
  min-width: auto !important;
  min-height: auto !important;
}

.react-pdf__Page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.first-part,
.second-part {
  box-shadow: 0 2px 40px -20px #c0c0c0;
  border-radius: 10px;
  margin-top: 15px;
}

.first-part {
  aspect-ratio: 3/4;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

canvas {
  height: 100% !important;
  width: 100% !important;
}

.second-part {
  padding: 15px 20px;
}

.research-field-text {
  color: var(--primary);
  text-decoration: underline;
  font-size: 0.875rem;
}

.research-field-text:hover {
  color: darkblue;
}

.research-title-text {
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
}

.second-part-third-row {
  display: flex;
  justify-content: space-between;
}

.research-author-name,
.date-published {
  color: var(--secondary);
  font-size: 0.875rem;
  font-style: italic;
}

.research-author-name {
  color: var(--primary);
  font-size: 0.875rem;
  font-style: italic;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 10px;
}

.filter-pop-out {
  display: none;
}

@media (max-width: 1000px) {
  .page-wrapper {
    flex-direction: column;
  }

  .filter-box {
    background-color: white;
  }

  .filter-wrap {
    position: relative;
    top: 0;
    display: flex;
    flex-direction: row;
    transition: transform 0.2s ease-in-out;
  }

  .filter-outer-wrap {
    position: fixed;
    top: calc(6.5rem + 20px);
    z-index: 10;
    overflow: hidden;
    
  }

  .shown {
    transform: translateX(0);
  }

  .hidden {
    transform: translateX(-260px);
  }

  .filter-wrap.hidden .filter-box {
    box-shadow: none;
  }

  .filter-pop-out {
    display: flex;
    align-self: center;
    border-radius: 0 10px 10px 0;
    box-shadow: 0 2px 40px -20px #c0c0c0;
    background-color: white;
    height: 3rem;
    font-size: 2rem;
    font-weight: bold;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px 10px 5px;
    border-right: 2px solid aliceblue;
    border-top: 2px solid aliceblue;
    border-bottom: 2px solid aliceblue;
  }

  .search-box {
    width: calc(100% - 20px);
  }

  .individual-card {
    width: 100%;
  }
}

.ios-blank-page {
  background-color: black;
  color: #969696;
  text-align: center;
  font-size: 1.1em;
  line-height: 1.8;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 5%;
  width: 100vw;
  height: 90vh;
}

  @supports (-webkit-touch-callout: none) {
    .page-wrapper {
      display: none
    }
    .ios-blank-page {
      display: flex
    }
  }

  @supports not (-webkit-touch-callout: none) {
    .page-wrapper {
      display: flex
    }
    .ios-blank-page {
      display: none
    }
  }
