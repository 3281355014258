/* .App {
  font-family: sans-serif;
  text-align: center;
}

html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  line-height: normal;
}

:root {

  --font-roboto: "Roboto", sans-serif;


  --font-size-lg: 18px;
  --font-size-31xl: 50px;


  --color-black: #000;
  --palette-1: #ffbc00;
  --palette-2: #2b52b7;


  --gap-31xl: 50px;
  --gap-xl: 20px;
}

.about {
  width: 100%;
}

.banner-icon {
  width: 1120px;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
}

.banner-icon,
.About-big-questions {
  position: relative;
}

.will-you-answer {
  color: var(--palette-2);
}

.answer {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 30px;
  color: var(--color-black);
}

.reason-why {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}

.terms-of-use-content {
  margin: 0;
}

.container-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-31xl);
}

.line,
.point {
  position: relative;
}

.line {
  align-self: stretch;
  border-right: 5px solid var(--palette-1);
  box-sizing: border-box;
  width: 5px;
}

.point {
  line-height: 26.8px;
  border: none;
  background-color: #0000;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  text-align: left;
  color: inherit;
  text-decoration: none;
}

.container1 {
  width: 170px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.sections {
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-xl);
  font-size: var(--font-size-lg);
  color: var(--color-black);
}

.about,
.sections,
.main-layout {
  display: flex;
  justify-content: flex-start;
}

.main-layout {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  gap: 200px;
}

.about {
  position: relative;
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  padding: 100px 160px;
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-31xl);
  text-align: left;
  font-size: var(--font-size-31xl);
  color: var(--palette-2);
  font-family: var(--font-roboto);
} */

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  margin: 0;
  padding: 0;
  --palette-1: #ffbc00;
  --palette-2: #2b52b7;
}

html {
  scroll-behavior: smooth;
}

.about {
  font-family: "Roboto", sans-serif;
  margin: 5% 10%;
}

.banner-icon {
  width: 100%;
}

.container-content {
  width: 70%;
  margin-right: 10%;
}

.About-big-questions {
  color: var(--palette-2);
  font-size: 2.5rem;
  font-weight: bold;
  margin: 1rem 0;
  /* scroll-margin-top: 3rem; */
  /* USE WITH STATIC NAVBAR */
  /* VALUE HAS TO BE EQUAL TO NAVBAR HEIGHT*/
}

.answer {
  line-height: 2;
  font-size: 1.1rem;
}

.container-content,
.sections {
  float: left;
}

.sections {
  width: 20%;
  margin-top: 1rem;
}

.line,
.container1 {
  float: left;
}

.line {
  height: 6rem;
  width: 4px;
  background-color: var(--palette-1);
  margin-right: 1.5rem;
}

.point {
  text-decoration: none;
  color: inherit;
  font-size: 1.2rem;
  line-height: 2rem;
}

@media (max-width: 1280px) {
  .container-content {
    width: 65%;
  }

  .sections {
    width: 25%;
  }
}

@media (max-width: 1000px) {
  .about {
    margin: 0;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .banner-icon {
    width: 100%;
  }

  .main-layout {
    display: flex;
    flex-direction: column-reverse;
  }

  .container-content,
  .sections {
    float: none;
  }

  .container-content {
    width: auto;
    margin: 0 5%;
  }

  .About-big-questions {
    font-size: 2rem;
  }

  .sections {
    width: auto;
    margin: 0 5% 5% 5%;
  }

  .reason-why:first-of-type .About-big-questions {
    margin-top: 0;
  }
}

@media (max-aspect-ratio: 1/1) {
  .about {
    margin: 0;
    margin-bottom: 2%;
  }

  .banner-icon {
    width: 100%;
  }

  .main-layout {
    display: flex;
    flex-direction: column-reverse;
  }

  .container-content,
  .sections {
    float: none;
  }

  .container-content {
    width: auto;
    margin: 0 5%;
  }

  .About-big-questions {
    font-size: 2rem;
  }

  .sections {
    width: auto;
    margin: 0 5% 5% 5%;
  }

  .reason-why:first-of-type .About-big-questions {
    margin-top: 0;
  }
}
